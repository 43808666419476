import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as groupsAnEnMGBKy4Meta } from "/vercel/path0/pages/groups.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as menusCTrteMyBdPMeta } from "/vercel/path0/pages/menus.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___nl",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___en",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/en/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___nl",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___en",
    path: contactJnsDpYVejzMeta?.path ?? "/en/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___nl",
    path: galleryjDl5RbNP1cMeta?.path ?? "/galerij",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___en",
    path: galleryjDl5RbNP1cMeta?.path ?? "/en/gallery",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: groupsAnEnMGBKy4Meta?.name ?? "groups___nl",
    path: groupsAnEnMGBKy4Meta?.path ?? "/groepen",
    meta: groupsAnEnMGBKy4Meta || {},
    alias: groupsAnEnMGBKy4Meta?.alias || [],
    redirect: groupsAnEnMGBKy4Meta?.redirect,
    component: () => import("/vercel/path0/pages/groups.vue").then(m => m.default || m)
  },
  {
    name: groupsAnEnMGBKy4Meta?.name ?? "groups___en",
    path: groupsAnEnMGBKy4Meta?.path ?? "/en/groups",
    meta: groupsAnEnMGBKy4Meta || {},
    alias: groupsAnEnMGBKy4Meta?.alias || [],
    redirect: groupsAnEnMGBKy4Meta?.redirect,
    component: () => import("/vercel/path0/pages/groups.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___nl",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/en",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menusCTrteMyBdPMeta?.name ?? "menus___nl",
    path: menusCTrteMyBdPMeta?.path ?? "/menus",
    meta: menusCTrteMyBdPMeta || {},
    alias: menusCTrteMyBdPMeta?.alias || [],
    redirect: menusCTrteMyBdPMeta?.redirect,
    component: () => import("/vercel/path0/pages/menus.vue").then(m => m.default || m)
  },
  {
    name: menusCTrteMyBdPMeta?.name ?? "menus___en",
    path: menusCTrteMyBdPMeta?.path ?? "/en/menus",
    meta: menusCTrteMyBdPMeta || {},
    alias: menusCTrteMyBdPMeta?.alias || [],
    redirect: menusCTrteMyBdPMeta?.redirect,
    component: () => import("/vercel/path0/pages/menus.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___nl",
    path: sitemapmtRscZaA8vMeta?.path ?? "/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___en",
    path: sitemapmtRscZaA8vMeta?.path ?? "/en/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]